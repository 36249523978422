ul
  &.base

    li
      min-height: var( --header-size )
      padding: 0 var( --gap )

      display: flex
      align-items: center

      &:nth-child( odd )
        background: var( --table-row-even )

      &:hover
        background: var( --table-row-hover )

  &.pointer
    li
      cursor: pointer

.list
  gap: 0
  .item
    padding: 0 var( --base )

    &:nth-child( odd )
      background: var( --table-row-even )

    &:hover
      background: var( --table-row-hover )
