.horizontal-timeline
  display: flex
  flex-direction: row
  justify-content: center

  gap: calc( var( --base ) * 7 )

  .timestamp
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    .icon
      font-size: calc( var( --base ) * 3.5 )

    .time
      font-size: calc( var( --base ) * 2.1 )

    & + .timestamp
      position: relative
      &:before
        content: ' '
        background: white
        width: calc( var( --base ) * 9 )
        height: 3px
        position: absolute

        top: calc( var( --base ) * 3 )
        left: calc( var( --base ) * -7 )
