.card
  background: var( --card-background )
  color: var( --card-color )

  strong
    padding: 0 calc( var( --base ) * 0.7 )
    font-style: italic
    background: rgba( 0, 0, 0, 0.21 )

  &.graph
    background: var( --graph-card-background )
    color: var( --graph-card-color )

  &.table
    background: var( --table-card-background )
    color: var( --table-card-color )

  &.info
    background: var( --info-background )
    color: var( --info-color )
    padding: calc( var( --base ) * 2.1 )

  &.warning
    background: rgba( 229, 211, 82, 1 )
    color: var( --warning-color )
    padding: calc( var( --base ) * 2.1 )

  &.success
    background: rgba(204, 245, 172, 1)
    color: var( --success-color )
    padding: calc( var( --base ) * 2.1 )
