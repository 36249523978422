// sizes
.text
  &.p70
    font-size: 70%

  &.p77
    font-size: 77%

  &.p107
    font-size: 107%

  &.p114
    font-size: 114%

  &.p121
    font-size: 121%

  &.p128
    font-size: 128%

  &.p135
    font-size: 135%

  &.p142
    font-size: 142%

  &.p149
    font-size: 149%

  &.x
    font-size: var( --1x )

  &.x2
    font-size: var( --2x )

  &.x3
    font-size: var( --3x )

  &.x4
    font-size: var( --4x )

  &.x5
    font-size: var( --5x )

  &.x6
    font-size: var( --6x )

  &.x7
    font-size: var( --7x )

  &.giant
    font-size: calc( var( --1x ) * 7.7 )

  &.centered
    text-align: center

.uppercase
  text-transform: uppercase

.bold
  font-weight: bold

.text-align-left
  text-align: left

.text-align-right
  text-align: right

.text-align-center
  text-align: center

strong
  font-weight: bold
