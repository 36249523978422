.modal
  background: var( --modal-transparency )
  width: 100%
  height: 100vh

  position: absolute
  top: 0
  left: 0

  display: flex
  justify-content: center
  align-items: center

  main
    flex: 0 0 25%
    height: auto
    max-height: 70vh

    background: var( --modal-background )
    color: var( --modal-color )
    z-index: 1

  &.x50
    main
      flex: 0 0 50%

    &.important
      main
        flex: 0 0 50% !important
        max-height: 70vh

  &.x70
    main
      flex: 0 0 70%

  &.full
    background: red
    main
      flex: 1
      display: flex
      height: 100vh
      max-height: 100vh

      section
        flex: 1

  &.tiny
    main
      flex: 0 0 25% !important
      max-height: 30vh

    &.important
      main
        flex: 0 0 25% !important
        max-height: 30vh

  &.important
    main
      flex: 0 0 25% !important
      max-height: 70vh
