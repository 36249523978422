button

  &.btn
    padding: 0 var( --gap )

    &.frm
      min-height: var( --header-size )
      min-width: var( --header-size )
      width: auto

    &.accent
      background: var( --accent )
      color: var( --accent-color )

    &.danger
      background: var( --danger )
      color: var( --danger-color )

    &:disabled
      cursor: not-allowed

    &.square
      width: var( --header-size )
      height: var( --header-size )
      display: flex
      justify-content: center
      align-items: center

    &.full
      width: auto

    &:hover
      opacity: 0.7
